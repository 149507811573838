import axios from 'axios'
import { storageService } from './storage.service'

const baseUrl = storageService.setApiUrl()

class UtmeFileManagement{
	apiUrl = baseUrl

	async fetchUTMEFile (folderName) {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/utme-load-project-file/${folderName}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchSubject (folderName) {
		const apiToken = await storageService.getToken()
		return await axios.get(`${baseUrl}/utme-load-subject/${folderName}`, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-create-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-update-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteSubject (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-delete-subject`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async createYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-create-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updateYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-update-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deleteYear (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-delete-examyear`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchPastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-load-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async fetchSinglePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-load-single-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async storePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-store-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deletePastQuestionImage (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-delete-past-question-image`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async updatePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-update-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}

	async deletePastQuestion (postData) {
		const apiToken = await storageService.getToken()
		return await axios.post(`${baseUrl}/utme-delete-past-question`, postData, {
			headers: {
				Authorization: `Bearer ${apiToken}`
			}
		})
	}
}

const utmeFileMgt = new UtmeFileManagement()
export { utmeFileMgt }